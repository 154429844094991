<template>
  <div>
    <GenericTable
      ref="lista"
      name="Motivos de Retorno"
      title="Motivos de Retorno"
      add-route="motivoRetornoAdicionar"
      edit-route="motivoRetornoEditar"
      :nofilters="true"
      :cols="['Motivo', 'Descrição']"
      :cols-name="['motivo', 'descricao']"
      :cols-map="i => [i.motivo, i.descricao]"
      :route="motivoRetornoRoute"
      :permissionsToWrite="['rw_material']"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      motivoRetornoRoute: 'motivoRetorno',
    };
  },
};
</script>
